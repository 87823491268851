/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box {
	@include flex-column;
	align-items:center;
}
	.box-content {
		width:100%;
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-01 {
}
	.box-01_content {
		margin:0 auto;
	}
