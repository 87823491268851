/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-primary {
    @include flex-column;
    width:100%;
    margin-top:24px; padding:24px 12px;
    border-top:1px solid; border-bottom:1px solid; border-color:#E5E5E5;
    box-sizing:border-box;

    @media screen and (max-width:768px)
    {
        padding:12px;
    }
}
    .nav-primary_list {
        @include flex-row;
        list-style:none;

        @media screen and (min-width:769px)
        {
            justify-content:center;
        }
        @media screen and (max-width:768px)
		{
            @include animate;
            flex-direction:column;
            position:fixed;
            top:0; left:0;
            z-index:99;
            width:100%; height:100%;
            overflow:auto;
            opacity:0;
            visibility:hidden;

            &:before,
            &:after {
                content:"";
                position:fixed;
                top:0; right:0;
                height:100%;
            }
            &:before {
                z-index:1;
                width:100%;
                background:rgba(#000, .72);
            }
            &:after {
                @include animate;
                z-index:2;
                width:calc(100% - 48px);
                background:#FFF;
            }

            &.is-active,
            &.is-active + .nav-primary_close {
                opacity:1;
                visibility:visible;
            }
        }
    }
        .nav-primary_item {
            padding:0 12px;
            line-height:$line_height_small;

            @media screen and (min-width:769px)
            {
                text-align:center;
            }
            @media screen and (max-width:768px)
    		{
                @include animate;
                position:relative;
                z-index:3;
                margin-left:48px; padding:6px 32px;

                &:first-child {
                    padding-top:32px;
                }
                &:last-child {
                    padding-bottom:32px;
                }
            }
        }
            .nav-primary_link {
                @include animate;
                display:block;
                color:$color_theme_01-01;
                font-size:$font_size_small;

                @media screen and (min-width:769px)
            	{
            		&:not(.is-current):hover,
            		&:not(.is-current):focus {
            			color:$color_theme_02-01;
            		}
            	}
                @media screen and (max-width:768px)
        		{
                    font-size:24px;
                }

                &.is-current {
                    color:$color_theme_02-01;
                }
            }

.nav-primary_close {
    @include animate;
    justify-content:center;
    position:fixed;
    top:0; left:0;
    z-index:100;
    width:48px; height:48px;
    opacity:0;
    visibility:hidden;

    svg {
        fill:#FFF;
    }
}
