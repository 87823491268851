/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-builder {
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}
}
	.pb-block {

		&:not(:first-child) {
			padding-top:48px;

			@media screen and (max-width:768px)
			{
				padding-top:24px;
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (MEDIA + TEXT) -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_media-text {

	.grid > .column + .column {

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
}
