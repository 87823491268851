/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin animate($speed: 300ms, $ease: ease-in-out) {
	transition-duration:$speed;
	transition-timing-function:$ease;
}
.animate {
	@include animate;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin flex {
	display:flex;
	flex-wrap:nowrap;
}
@mixin flex-row {
	@include flex;
	flex-direction:row;
}
@mixin flex-column {
	@include flex;
	flex-direction:column;
}

.flex-row {
	@include flex-row;
}
.flex-column {
	@include flex-column;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wLarge {
	max-width:$site_width_large;
}
.wBase {
	max-width:$site_width_base;
}
.wMedium {
	max-width:$site_width_medium;
}
.wSmall {
	max-width:$site_width_small;
}
.wXSmall {
	max-width:$site_width_xsmall;
}
.wXXSmall {
	max-width:$site_width_xxsmall;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_24 {
	padding:24px;
}
.bleed_48 {
	padding:48px;
}
.bleed_96 {
	padding:96px;
}
.bleed_top_96 {
	padding-top:96px;
}

@media screen and (max-width:1220px) and (min-width:769px)
{
	.bleed_96 {
		padding:48px;
	}
	.bleed_top_96 {
		padding-top:48px;
	}
}
@media screen and (max-width:768px)
{
	.bleed_48,
	.bleed_96 {
		padding:24px;
	}
	.bleed_top_96 {
		padding-top:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
	margin:0 !important;
}
.reset_margin_top {
	margin-top:0 !important;
}
.reset_margin_right {
	margin-right:0 !important;
}
.reset_margin_bottom {
	margin-bottom:0 !important;
}
.reset_margin_left {
	margin-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
	padding:0 !important;
}
.reset_padding_top {
	padding-top:0 !important;
}
.reset_padding_right {
	padding-right:0 !important;
}
.reset_padding_bottom {
	padding-bottom:0 !important;
}
.reset_padding_left {
	padding-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
	border:0 solid !important;
}
.reset_border_top {
	border-top:0 solid !important;
}
.reset_border_right {
	border-right:0 solid !important;
}
.reset_border_bottom {
	border-bottom:0 solid !important;
}
.reset_border_left {
	border-left:0 solid !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin relative($zindex: 1) {
	position:relative;
	z-index:$zindex;
}
.relative {
	@include relative;
}

@mixin absolute($zindex: 1) {
	position:absolute;
	z-index:$zindex;
}
.absolute {
	@include absolute;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.color_01-01 {
	color:$color_theme_01-01 !important;
}
a.color_01-01,
button.color_01-01 {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_01-01 !important;
			cursor:pointer;
		}
	}
}

/* --- Red --- */
.color_02-01 {
	color:$color_theme_02-01 !important;
}
a.color_02-01,
button.color_02-01 {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:$color_theme_02-01 !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.color_white {
	color:#FFF !important;
}
a.color_white,
button.color_white {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.bg_01-01 {
	background:$color_theme_01-01 !important;
}
a.bg_01-01,
button.bg_01-01, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_01-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Red --- */
.bg_02-01 {
	background:$color_theme_02-01 !important;
}
a.bg_02-01,
button.bg_02-01, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_theme_02-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Error --- */
.bg_error {
	background:$color_error !important;
}
a.bg_error,
button.bg_error, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_error, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Alert --- */
.bg_alert {
	background:$color_alert !important;
}
a.bg_alert,
button.bg_alert, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_alert, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Valid --- */
.bg_valid {
	background:$color_valid !important;
}
a.bg_valid,
button.bg_valid, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_valid, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Update --- */
.bg_update {
	background:$color_update !important;
}
a.bg_update,
button.bg_update, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background:darken($color_update, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.bg_white {
	background:#FFF !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- White --- */
.border_white {
	border-color:#FFF !important;
}
a.border_white,
button.border_white, {
	@include animate;

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			border-color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.fill_01-01 svg {
	fill:$color_theme_01-01 !important;
}

/* --- White --- */
.fill_white svg {
	fill:#FFF !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin full-size($zindex: 1) {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:$zindex;
}
.full-size {
	@include full-size;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin full-width {
	display:block;
	width:100%; height:auto;
}
.full-width {
	@include full-width;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIGURE ASPECT RATIO ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin figure_aspect-ratio($aspect-ratio)
{
	content:"";
	display:block;
	padding-top:calc((#{$aspect-ratio}) * 100%);
	width:100%; height:0;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hide-desktop {

	@media screen and (min-width:769px)
	{
		display:none !important;
	}
}

.hide-mobile {

	@media screen and (max-width:768px)
	{
		display:none !important;
	}
}
