/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (ONLINE APPLICATION) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.from_apply-online_btn-wrapper {
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field {
	padding-top:24px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
	.field-label {
		@include flex-row;
		padding-bottom:6px;
		color:$color_theme_01-01;

		em {
			margin-left:auto; padding-left:24px;
			color:$color_theme_01-02;
			font-weight:400;
			text-transform:none;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-feedback {
	@include animate;
	@include relative;
	max-height:0;
	overflow:hidden;
	opacity:0;

	span {
		display:block;
		padding-top:12px;
		line-height:$line_height_small;
		font-size:$font_size_small;
		color:$color_error;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD NOTE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-note {
	display:block;
	padding-top:12px;
	line-height:1.2;
	font-size:14px;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.badge-valid,
.badge-invalid {
	@include animate;
	@include flex-column;
	justify-content:center;
	align-items:center;
	position:absolute;
	top:-12px; right:-12px;
	z-index:2;
	width:24px; height:24px;
	border-radius:100%;
	opacity:0;

	svg {
		display:block;
		width:12px; height:12px;
		fill:#FFF;
	}
}
.badge-valid {
	background:$color_valid;
}
.badge-invalid {
	background:$color_error;
}

.input-checkbox {

	.badge-valid,
	.badge-invalid {
		top:50%; left:19px; right:auto;
		margin-top:-11px;
		width:8px; height:8px;
		transform:translateY(-4px);

		svg {
			display:none;
		}
	}
}
.input-radio {

	.badge-valid,
	.badge-invalid {
		top:50%; left:16px; right:auto;
		margin-top:-8px;
		width:8px; height:8px;
		transform:translateY(-4px);

		svg {
			display:none;
		}
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.badge-invalid {
		opacity:0;
	}
	.badge-valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.badge-valid {
		opacity:0;
	}
	.field-feedback,
	.badge-invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-text {
	@include animate;
	@include relative;

	input,
	textarea {
		@include animate;
		margin:0; padding:16px 24px;
		width:100%; height:auto;
		line-height:$line_height_small;
		color:$color_theme_01-02;
		font-family:$font_family_01;
		font-size:100%; font-size:$font_size_small;
		background:#FFF;
		border:2px solid #E5E5E5;
		box-sizing:border-box;
		appearance:none;
	}
	input {

		&:not([value=""]) + .label_in-field,
		&:focus + .label_in-field {
			z-index:1;
			padding:6px 24px;
			font-size:8px;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				position:relative;
				z-index:2;
				border:2px solid #C5C5C5;
			}
		}

		&:disabled {
			color:rgba($color_theme_01-01, .24);
			cursor:default;

			& + svg {
				opacity:.24;
			}
		}
	}

	.label_in-field {
		@include animate;
		@include full-size;
		padding:16px 24px;
		line-height:$line_height_small;
		color:$color_theme_01-01;
		font-size:$font_size_base;
		box-sizing:border-box;
		cursor:pointer;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-radio,
.input-checkbox {
	@include animate;
	@include relative;
	@include flex-row;
	align-items:center;

	input {
		position:absolute;
		top:50%; left:0;
		z-index:1;
		margin:0; padding:0;
		width:24px; height:24px;
		line-height:1;
		color:$color_theme_01-01;
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		background:none;
		border:0 solid;
		border-radius:0;
		opacity:0;
		cursor:pointer;
		appearance:none;
		transform:translateY(-50%);

		&:checked:not(:disabled) + span,
		&:checked:not(:disabled) + span {

			&:after,
			svg {
				opacity:1;
			}
		}

		@media screen and (min-width:769px)
		{

			&:not(:disabled):hover + span,
			&:not(:disabled):focus + span {
				border-color:#C5C5C5;
			}
		}

		&:disabled {
			cursor:default;

			& + span + label {
				opacity:.24;
				cursor:default;
			}
		}
	}

	input + span {
		@include animate;
		@include flex-column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:24px; height:24px;
		background:#FFF;
		border:2px solid #E5E5E5;
		box-sizing:border-box;

		&:after,
		svg {
			@include animate;
			display:block;
			opacity:0;
		}
	}

	input + span + label {
		flex:1 1 auto;
		flex-direction:column;
		margin:0; padding:0 0 0 12px;
		line-height:1.2;
		cursor:pointer;

		a {
			@include animate;
			color:$color_theme_01-01;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_02-01;
				}
			}
		}
	}

	& + .field-feedback:before {
		left:6px;
	}
}

.input-radio {

	input + span {
		border-radius:100%;

		&:after {
			content:"";
			width:8px; height:8px;
			background:$color_theme_01-01;
			border-radius:100%;
		}
	}
}

.input-checkbox {

	input + span {

		svg {
			width:12px; height:12px;
			fill:$color_theme_01-01;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUCCESS / ERROR MESSAGE ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.modal-success_wrapper,
.modal-error_wrapper {

	.modal_btn-close {
		position:absolute;
		top:24px; right:24px;

		@media screen and (max-width:768px)
		{
			top:12px; right:12px;
		}
	}
}
	.modal-success,
	.modal-error {
		text-align:center;

		.btn-03 {
			min-width:200px;
		}
	}

.modal-success_wrapper {

	.modal_btn-close {
		background:darken($color_theme_02-02, 18%);

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				background:darken($color_theme_02-02, 24%);
			}
		}
	}
}
