/* BASE */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- PRIMARY --- */
/* --- SYSTEM COLORS --- */
/* --- SOCIAL COLORS --- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FADE IN ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@keyframes fadeIn {
  from {
    opacity: 0; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline; }

html {
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #FFF; }

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #666;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;
  cursor: default;
  overflow-x: auto;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    body {
      line-height: 1.4;
      font-size: 16px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
  color: #FFF;
  background-color: #242430; }

::-moz-selection {
  color: #FFF;
  background-color: #242430; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flex-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row; }

.flex-column {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wLarge {
  max-width: 1440px; }

.wBase {
  max-width: 1220px; }

.wMedium {
  max-width: 980px; }

.wSmall {
  max-width: 860px; }

.wXSmall {
  max-width: 640px; }

.wXXSmall {
  max-width: 420px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_24 {
  padding: 24px; }

.bleed_48 {
  padding: 48px; }

.bleed_96 {
  padding: 96px; }

.bleed_top_96 {
  padding-top: 96px; }

@media screen and (max-width: 1220px) and (min-width: 769px) {
  .bleed_96 {
    padding: 48px; }
  .bleed_top_96 {
    padding-top: 48px; } }

@media screen and (max-width: 768px) {
  .bleed_48,
  .bleed_96 {
    padding: 24px; }
  .bleed_top_96 {
    padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
  margin: 0 !important; }

.reset_margin_top {
  margin-top: 0 !important; }

.reset_margin_right {
  margin-right: 0 !important; }

.reset_margin_bottom {
  margin-bottom: 0 !important; }

.reset_margin_left {
  margin-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
  padding: 0 !important; }

.reset_padding_top {
  padding-top: 0 !important; }

.reset_padding_right {
  padding-right: 0 !important; }

.reset_padding_bottom {
  padding-bottom: 0 !important; }

.reset_padding_left {
  padding-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
  border: 0 solid !important; }

.reset_border_top {
  border-top: 0 solid !important; }

.reset_border_right {
  border-right: 0 solid !important; }

.reset_border_bottom {
  border-bottom: 0 solid !important; }

.reset_border_left {
  border-left: 0 solid !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative {
  position: relative;
  z-index: 1; }

.absolute {
  position: absolute;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.color_01-01 {
  color: #242430 !important; }

a.color_01-01,
button.color_01-01 {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.color_01-01:not(:disabled):hover, a.color_01-01:not(:disabled):focus,
    button.color_01-01:not(:disabled):hover,
    button.color_01-01:not(:disabled):focus {
      color: #242430 !important;
      cursor: pointer; } }

/* --- Red --- */
.color_02-01 {
  color: #C43B2B !important; }

a.color_02-01,
button.color_02-01 {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.color_02-01:not(:disabled):hover, a.color_02-01:not(:disabled):focus,
    button.color_02-01:not(:disabled):hover,
    button.color_02-01:not(:disabled):focus {
      color: #C43B2B !important;
      cursor: pointer; } }

/* --- White --- */
.color_white {
  color: #FFF !important; }

a.color_white,
button.color_white {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.color_white:not(:disabled):hover, a.color_white:not(:disabled):focus,
    button.color_white:not(:disabled):hover,
    button.color_white:not(:disabled):focus {
      color: #FFF !important;
      cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.bg_01-01 {
  background: #242430 !important; }

a.bg_01-01,
button.bg_01-01 {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_01-01:not(:disabled):hover, a.bg_01-01:not(:disabled):focus,
    button.bg_01-01:not(:disabled):hover,
    button.bg_01-01:not(:disabled):focus {
      background: #101016 !important;
      cursor: pointer; } }

/* --- Red --- */
.bg_02-01 {
  background: #C43B2B !important; }

a.bg_02-01,
button.bg_02-01 {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_02-01:not(:disabled):hover, a.bg_02-01:not(:disabled):focus,
    button.bg_02-01:not(:disabled):hover,
    button.bg_02-01:not(:disabled):focus {
      background: #9e3023 !important;
      cursor: pointer; } }

/* --- Error --- */
.bg_error {
  background: #D91E18 !important; }

a.bg_error,
button.bg_error {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_error:not(:disabled):hover, a.bg_error:not(:disabled):focus,
    button.bg_error:not(:disabled):hover,
    button.bg_error:not(:disabled):focus {
      background: #b01813 !important;
      cursor: pointer; } }

/* --- Alert --- */
.bg_alert {
  background: #F89406 !important; }

a.bg_alert,
button.bg_alert {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_alert:not(:disabled):hover, a.bg_alert:not(:disabled):focus,
    button.bg_alert:not(:disabled):hover,
    button.bg_alert:not(:disabled):focus {
      background: #cb7905 !important;
      cursor: pointer; } }

/* --- Valid --- */
.bg_valid {
  background: #00B16A !important; }

a.bg_valid,
button.bg_valid {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_valid:not(:disabled):hover, a.bg_valid:not(:disabled):focus,
    button.bg_valid:not(:disabled):hover,
    button.bg_valid:not(:disabled):focus {
      background: #00834f !important;
      cursor: pointer; } }

/* --- Update --- */
.bg_update {
  background: #1E8BC3 !important; }

a.bg_update,
button.bg_update {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.bg_update:not(:disabled):hover, a.bg_update:not(:disabled):focus,
    button.bg_update:not(:disabled):hover,
    button.bg_update:not(:disabled):focus {
      background: #186f9b !important;
      cursor: pointer; } }

/* --- White --- */
.bg_white {
  background: #FFF !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- White --- */
.border_white {
  border-color: #FFF !important; }

a.border_white,
button.border_white {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }
  @media screen and (min-width: 769px) {
    a.border_white:not(:disabled):hover, a.border_white:not(:disabled):focus,
    button.border_white:not(:disabled):hover,
    button.border_white:not(:disabled):focus {
      border-color: #FFF !important;
      cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.fill_01-01 svg {
  fill: #242430 !important; }

/* --- White --- */
.fill_white svg {
  fill: #FFF !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full-size {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full-width {
  display: block;
  width: 100%;
  height: auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIGURE ASPECT RATIO ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .hide-desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Widths */
  /* Column */
  /* Gutter 96px */
  /* Gutter 48px */
  /* Gutter 24px */ }
  .grid.grid_reverse {
    flex-direction: row-reverse; }
  .grid.grid_valign-middle {
    align-items: center; }
  .grid.grid_valign-bottom {
    align-items: flex-end; }
  @media screen and (max-width: 768px) {
    .grid {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .grid .width_01-12 {
    width: calc(1 / 12 * 100%); }
  .grid .width_02-12 {
    width: calc(2 / 12 * 100%); }
  .grid .width_03-12 {
    width: calc(3 / 12 * 100%); }
  .grid .width_04-12 {
    width: calc(4 / 12 * 100%); }
  .grid .width_05-12 {
    width: calc(5 / 12 * 100%); }
  .grid .width_06-12 {
    width: calc(6 / 12 * 100%); }
  .grid .width_07-12 {
    width: calc(7 / 12 * 100%); }
  .grid .width_08-12 {
    width: calc(8 / 12 * 100%); }
  .grid .width_09-12 {
    width: calc(9 / 12 * 100%); }
  .grid .width_10-12 {
    width: calc(10 / 12 * 100%); }
  .grid .width_11-12 {
    width: calc(11 / 12 * 100%); }
  .grid .width_12-12 {
    width: calc(12 / 12 * 100%); }
  .grid .column {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .grid .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%; } }
  .grid.gutter_96 {
    margin-left: -48px;
    margin-right: -48px; }
    .grid.gutter_96 > .column {
      padding-left: 48px;
      padding-right: 48px; }
  .grid.gutter_48 {
    margin-left: -24px;
    margin-right: -24px; }
    .grid.gutter_48 > .column {
      padding-left: 24px;
      padding-right: 24px; }
  .grid.gutter_24 {
    margin-left: -12px;
    margin-right: -12px; }
    .grid.gutter_24 > .column {
      padding-left: 12px;
      padding-right: 12px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: 1.6;
  font-family: "Raleway", sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  appearance: none; }

[type=tel],
[type=text],
[type=email],
[type=password],
[type=file] {
  display: block;
  width: 100%;
  background: transparent; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
  background: none;
  cursor: pointer; }

[type=submit] {
  cursor: pointer;
  overflow: visible; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  display: block;
  width: 100%;
  background: transparent;
  resize: none;
  overflow: auto;
  box-sizing: border-box; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

[hidden] {
  display: none; }

select::-ms-expand {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 0;
  color: #242430;
  font-weight: 700;
  font-family: "Gelasio", serif; }

h1, .h1 {
  line-height: 1.2;
  font-size: 64px; }
  @media screen and (max-width: 768px) {
    h1, .h1 {
      font-size: 32px; } }

h2, .h2 {
  line-height: 1.3;
  font-size: 48px; }
  @media screen and (max-width: 768px) {
    h2, .h2 {
      font-size: 24px; } }

h3, .h3 {
  line-height: 1.4;
  font-size: 32px; }
  @media screen and (max-width: 768px) {
    h3, .h3 {
      font-size: 24px; } }

h4, .h4 {
  line-height: 1.5;
  font-size: 24px; }
  @media screen and (max-width: 768px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5 {
  line-height: 1.6;
  font-size: 18px; }

h6, .h6 {
  line-height: 1.4;
  font-size: 16px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
  color: #242430;
  text-decoration: none;
  text-decoration-skip: objects;
  background-color: transparent; }
  a:active, a:hover {
    outline-width: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
  font-weight: inherit;
  font-weight: bold; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
  font-style: italic; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
  color: #333;
  background: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
  font-size: 16px; }

sub,
sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
  display: block;
  margin: 0; }
  figure figcaption {
    display: block; }

img {
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic; }
  img.align_left {
    float: left; }
  img.align_right {
    float: right; }

svg:not(:root) {
  overflow: hidden; }

/* PLUGINS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper {
  position: relative;
  z-index: 1; }
  .lazyload_wrapper picture,
  .lazyload_wrapper img {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    opacity: 0; }
    .lazyload_wrapper picture.lazyloaded,
    .lazyload_wrapper img.lazyloaded {
      opacity: 1; }
  .lazyload_wrapper picture.lazyloaded img {
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js .lazyload_wrapper picture,
.no-js .lazyload_wrapper img {
  opacity: 1; }

/* COMPONENTS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
  display: inline-block;
  font-weight: 700;
  box-sizing: border-box; }
  .btn.btn_has-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .btn.btn_has-icon span {
      padding-left: 12px; }
      @media screen and (max-width: 768px) {
        .btn.btn_has-icon span {
          padding-left: 9px; } }
      .btn.btn_has-icon span:first-child {
        padding-left: 0;
        padding-right: 12px; }
        @media screen and (max-width: 768px) {
          .btn.btn_has-icon span:first-child {
            padding-right: 9px; } }
    .btn.btn_has-icon svg {
      display: block;
      flex-shrink: 0;
      width: 18px;
      height: 18px; }
    .btn.btn_has-icon .btn-icon {
      flex-shrink: 0; }
  .btn.icon-minus-90-deg svg {
    transform: rotate(-90deg); }
  .btn.btn-ghost {
    opacity: .24; }
    @media screen and (min-width: 769px) {
      .btn.btn-ghost:not(:disabled):hover, .btn.btn-ghost:not(:disabled):focus {
        opacity: 1; } }
  .btn.btn-round {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden; }
    .btn.btn-round svg {
      display: block;
      flex-shrink: 0;
      width: 18px;
      height: 18px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-01 {
  padding: 16px 28px;
  border-radius: 3px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (ONLINE APPLICATION) ---------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.from_apply-online_btn-wrapper {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .from_apply-online_btn-wrapper {
      padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field {
  padding-top: 24px; }
  @media screen and (max-width: 768px) {
    .field {
      padding-top: 18px; } }

.field-label {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-bottom: 6px;
  color: #242430; }
  .field-label em {
    margin-left: auto;
    padding-left: 24px;
    color: #666;
    font-weight: 400;
    text-transform: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-feedback {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  opacity: 0; }
  .field-feedback span {
    display: block;
    padding-top: 12px;
    line-height: 1.4;
    font-size: 16px;
    color: #D91E18; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD NOTE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-note {
  display: block;
  padding-top: 12px;
  line-height: 1.2;
  font-size: 14px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.badge-valid,
.badge-invalid {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  opacity: 0; }
  .badge-valid svg,
  .badge-invalid svg {
    display: block;
    width: 12px;
    height: 12px;
    fill: #FFF; }

.badge-valid {
  background: #00B16A; }

.badge-invalid {
  background: #D91E18; }

.input-checkbox .badge-valid,
.input-checkbox .badge-invalid {
  top: 50%;
  left: 19px;
  right: auto;
  margin-top: -11px;
  width: 8px;
  height: 8px;
  transform: translateY(-4px); }
  .input-checkbox .badge-valid svg,
  .input-checkbox .badge-invalid svg {
    display: none; }

.input-radio .badge-valid,
.input-radio .badge-invalid {
  top: 50%;
  left: 16px;
  right: auto;
  margin-top: -8px;
  width: 8px;
  height: 8px;
  transform: translateY(-4px); }
  .input-radio .badge-valid svg,
  .input-radio .badge-invalid svg {
    display: none; }

/* --- Valid --- */
.is-valid .field-feedback,
.is-valid .badge-invalid {
  opacity: 0; }

.is-valid .badge-valid {
  opacity: 1; }

/* --- Invalid --- */
.is-invalid .badge-valid {
  opacity: 0; }

.is-invalid .field-feedback,
.is-invalid .badge-invalid {
  opacity: 1; }

.is-invalid .field-feedback {
  max-height: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-text {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 1; }
  .input-text input,
  .input-text textarea {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    margin: 0;
    padding: 16px 24px;
    width: 100%;
    height: auto;
    line-height: 1.4;
    color: #666;
    font-family: "Raleway", sans-serif;
    font-size: 100%;
    font-size: 16px;
    background: #FFF;
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    appearance: none; }
  .input-text input:not([value=""]) + .label_in-field,
  .input-text input:focus + .label_in-field {
    z-index: 1;
    padding: 6px 24px;
    font-size: 8px;
    opacity: .48; }
  @media screen and (min-width: 769px) {
    .input-text input:not(:disabled):hover, .input-text input:not(:disabled):focus {
      position: relative;
      z-index: 2;
      border: 2px solid #C5C5C5; } }
  .input-text input:disabled {
    color: rgba(36, 36, 48, 0.24);
    cursor: default; }
    .input-text input:disabled + svg {
      opacity: .24; }
  .input-text .label_in-field {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 16px 24px;
    line-height: 1.4;
    color: #242430;
    font-size: 18px;
    box-sizing: border-box;
    cursor: pointer; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-radio,
.input-checkbox {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center; }
  .input-radio input,
  .input-checkbox input {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    color: #242430;
    font-size: 100%;
    font-size: 18px;
    box-sizing: border-box;
    background: none;
    border: 0 solid;
    border-radius: 0;
    opacity: 0;
    cursor: pointer;
    appearance: none;
    transform: translateY(-50%); }
    .input-radio input:checked:not(:disabled) + span:after,
    .input-radio input:checked:not(:disabled) + span svg,
    .input-radio input:checked:not(:disabled) + span:after,
    .input-radio input:checked:not(:disabled) + span svg,
    .input-checkbox input:checked:not(:disabled) + span:after,
    .input-checkbox input:checked:not(:disabled) + span svg,
    .input-checkbox input:checked:not(:disabled) + span:after,
    .input-checkbox input:checked:not(:disabled) + span svg {
      opacity: 1; }
    @media screen and (min-width: 769px) {
      .input-radio input:not(:disabled):hover + span,
      .input-radio input:not(:disabled):focus + span,
      .input-checkbox input:not(:disabled):hover + span,
      .input-checkbox input:not(:disabled):focus + span {
        border-color: #C5C5C5; } }
    .input-radio input:disabled,
    .input-checkbox input:disabled {
      cursor: default; }
      .input-radio input:disabled + span + label,
      .input-checkbox input:disabled + span + label {
        opacity: .24;
        cursor: default; }
  .input-radio input + span,
  .input-checkbox input + span {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: #FFF;
    border: 2px solid #E5E5E5;
    box-sizing: border-box; }
    .input-radio input + span:after,
    .input-radio input + span svg,
    .input-checkbox input + span:after,
    .input-checkbox input + span svg {
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      display: block;
      opacity: 0; }
  .input-radio input + span + label,
  .input-checkbox input + span + label {
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0;
    padding: 0 0 0 12px;
    line-height: 1.2;
    cursor: pointer; }
    .input-radio input + span + label a,
    .input-checkbox input + span + label a {
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      color: #242430;
      text-decoration: underline; }
      @media screen and (min-width: 769px) {
        .input-radio input + span + label a:hover, .input-radio input + span + label a:focus,
        .input-checkbox input + span + label a:hover,
        .input-checkbox input + span + label a:focus {
          color: #C43B2B; } }
  .input-radio + .field-feedback:before,
  .input-checkbox + .field-feedback:before {
    left: 6px; }

.input-radio input + span {
  border-radius: 100%; }
  .input-radio input + span:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #242430;
    border-radius: 100%; }

.input-checkbox input + span svg {
  width: 12px;
  height: 12px;
  fill: #242430; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUCCESS / ERROR MESSAGE ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.modal-success_wrapper .modal_btn-close,
.modal-error_wrapper .modal_btn-close {
  position: absolute;
  top: 24px;
  right: 24px; }
  @media screen and (max-width: 768px) {
    .modal-success_wrapper .modal_btn-close,
    .modal-error_wrapper .modal_btn-close {
      top: 12px;
      right: 12px; } }

.modal-success,
.modal-error {
  text-align: center; }
  .modal-success .btn-03,
  .modal-error .btn-03 {
    min-width: 200px; }

.modal-success_wrapper .modal_btn-close {
  background: #44a599; }
  @media screen and (min-width: 769px) {
    .modal-success_wrapper .modal_btn-close:hover, .modal-success_wrapper .modal_btn-close:focus {
      background: #3b8f85; } }

/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.wrapper_video {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  padding-top: calc((9 / 16) * 100%);
  width: 100%;
  height: 0; }
  .wrapper_video video,
  .wrapper_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }

/* --------------------------------------------------------------------------------------------------- */
/* VIDEO (MODAL) ------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .modal-video_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    background: rgba(0, 0, 0, 0.88) !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center; }

.box-content {
  width: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box-01_content {
  margin: 0 auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-primary {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  padding: 24px 12px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #E5E5E5;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .nav-primary {
      padding: 12px; } }

.nav-primary_list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  list-style: none; }
  @media screen and (min-width: 769px) {
    .nav-primary_list {
      justify-content: center; } }
  @media screen and (max-width: 768px) {
    .nav-primary_list {
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      overflow: auto;
      opacity: 0;
      visibility: hidden; }
      .nav-primary_list:before, .nav-primary_list:after {
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        height: 100%; }
      .nav-primary_list:before {
        z-index: 1;
        width: 100%;
        background: rgba(0, 0, 0, 0.72); }
      .nav-primary_list:after {
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
        z-index: 2;
        width: calc(100% - 48px);
        background: #FFF; }
      .nav-primary_list.is-active,
      .nav-primary_list.is-active + .nav-primary_close {
        opacity: 1;
        visibility: visible; } }

.nav-primary_item {
  padding: 0 12px;
  line-height: 1.4; }
  @media screen and (min-width: 769px) {
    .nav-primary_item {
      text-align: center; } }
  @media screen and (max-width: 768px) {
    .nav-primary_item {
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      position: relative;
      z-index: 3;
      margin-left: 48px;
      padding: 6px 32px; }
      .nav-primary_item:first-child {
        padding-top: 32px; }
      .nav-primary_item:last-child {
        padding-bottom: 32px; } }

.nav-primary_link {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  color: #242430;
  font-size: 16px; }
  @media screen and (min-width: 769px) {
    .nav-primary_link:not(.is-current):hover, .nav-primary_link:not(.is-current):focus {
      color: #C43B2B; } }
  @media screen and (max-width: 768px) {
    .nav-primary_link {
      font-size: 24px; } }
  .nav-primary_link.is-current {
    color: #C43B2B; }

.nav-primary_close {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 48px;
  height: 48px;
  opacity: 0;
  visibility: hidden; }
  .nav-primary_close svg {
    fill: #FFF; }

/* --------------------------------------------------------------------------------------------------- */
/* SOCIAL (NAVIGATION) ------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (CASE STUDIES) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.case-studies_item {
  padding-top: 1.5em; }
  @media screen and (max-width: 768px) {
    .case-studies_item {
      padding-top: 2em; } }

@media screen and (max-width: 768px) {
  .case-studies_item-thumb {
    padding-bottom: 1em; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (APPLICANTS) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.table-applicants_wrapper {
  width: 100%;
  overflow-x: auto; }

.table-applicants {
  min-width: 600px; }
  .table-applicants th {
    color: #242430;
    font-size: 16px;
    text-align: left;
    background: #F5F5F5; }
  .table-applicants td {
    font-size: 14px; }

.table-applicants_action {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row; }
  .table-applicants_action a {
    flex-shrink: 0;
    margin-right: 3px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (PAGINATION) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 48px 0 0;
  list-style: none; }

.page-item {
  margin: 0 3px; }
  .page-item.disabled .page-link {
    color: #999; }
  .page-item.active .page-link {
    color: #FFF;
    background: #C43B2B; }
  @media screen and (min-width: 769px) {
    .page-item:not(.active):not(.disabled) .page-link:hover,
    .page-item:not(.active):not(.disabled) .page-link:focus {
      background: #E5E5E5; } }

.page-link {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  color: #242430;
  background: #F5F5F5;
  border-radius: 3px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
  color: #666; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
    padding-top: 1.5em; }
    @media screen and (max-width: 768px) {
      .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
        padding-top: 1em; } }
    .typography h1:first-child, .typography h2:first-child, .typography h3:first-child, .typography h4:first-child, .typography h5:first-child, .typography h6:first-child, .typography p:first-child, .typography ul:first-child, .typography ol:first-child {
      padding-top: 0; }
  .typography.half-spacing h1, .typography.half-spacing h2, .typography.half-spacing h3, .typography.half-spacing h4, .typography.half-spacing h5, .typography.half-spacing h6, .typography.half-spacing p, .typography.half-spacing ul, .typography.half-spacing ol {
    padding-top: .75em; }
    @media screen and (max-width: 768px) {
      .typography.half-spacing h1, .typography.half-spacing h2, .typography.half-spacing h3, .typography.half-spacing h4, .typography.half-spacing h5, .typography.half-spacing h6, .typography.half-spacing p, .typography.half-spacing ul, .typography.half-spacing ol {
        padding-top: .375em; } }
    .typography.half-spacing h1:first-child, .typography.half-spacing h2:first-child, .typography.half-spacing h3:first-child, .typography.half-spacing h4:first-child, .typography.half-spacing h5:first-child, .typography.half-spacing h6:first-child, .typography.half-spacing p:first-child, .typography.half-spacing ul:first-child, .typography.half-spacing ol:first-child {
      padding-top: 0; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography strong {
    color: #242430; }
  .typography ul, .typography ol {
    padding-left: 18px; }
    @media screen and (min-width: 769px) {
      .typography ul, .typography ol {
        margin-top: -.5em; } }
  .typography a:not(.btn):not(.link) {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    position: relative;
    z-index: 1;
    text-decoration: none;
    word-break: break-word;
    border-bottom: 1px solid #242430; }
    @media screen and (min-width: 769px) {
      .typography a:not(.btn):not(.link):hover, .typography a:not(.btn):not(.link):focus {
        color: #C43B2B;
        border-bottom: 1px solid transparent; } }
  .typography blockquote {
    position: relative;
    z-index: 1;
    padding: 48px 0;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .typography blockquote {
        padding: 18px 0; } }
    .typography blockquote:before, .typography blockquote:after {
      position: absolute;
      z-index: 1;
      content: "";
      left: 96px;
      right: 96px;
      height: 1px;
      background: #242430; }
      @media screen and (max-width: 768px) {
        .typography blockquote:before, .typography blockquote:after {
          left: 24px;
          right: 24px; } }
    .typography blockquote:before {
      top: 0; }
    .typography blockquote:after {
      bottom: 0; }
  .typography hr {
    margin: 1.5em 0 0;
    padding: 0;
    width: 100%;
    height: 1px;
    background: #E5E5E5;
    border: none; }
    @media screen and (max-width: 768px) {
      .typography hr {
        margin-top: 1em; } }
    .typography hr:first-child {
      margin-top: 0; }
  .typography table {
    margin-top: 1.5em;
    width: 100%;
    border-collapse: collapse; }
    @media screen and (max-width: 768px) {
      .typography table {
        padding-top: 1em; } }
    .typography table:first-child {
      padding-top: 0; }
    .typography table th, .typography table td {
      padding: 9px 18px;
      border: 1px solid #E5E5E5; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-builder {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .page-builder {
      padding-top: 24px; } }

.pb-block:not(:first-child) {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .pb-block:not(:first-child) {
      padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (MEDIA + TEXT) -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .pb-block_media-text .grid > .column + .column {
    padding-top: 24px; } }

/* LAYOUT */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* HTML ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
html {
  min-height: 100vh;
  background: url("../images/layout/body-bg.jpg") top left no-repeat #F5F5F5;
  background-attachment: fixed;
  background-size: cover;
  object-fit: cover;
  font-family: 'object-fit:cover;'; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-shrink: 0; }
  .wrapper > .box-01 {
    margin: 48px; }
    @media screen and (max-width: 768px) {
      .wrapper > .box-01 {
        margin: 6px; } }
    .wrapper > .box-01 > .box-01_content {
      width: 100%;
      box-sizing: border-box; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1 1 auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
  text-align: center; }
  @media screen and (max-width: 768px) {
    .logo {
      line-height: 1.2; }
      .logo strong {
        font-size: 18px; }
      .logo span {
        font-size: 14px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
  padding-top: 48px;
  margin-top: 48px;
  border-top: 1px solid #E5E5E5; }
  @media screen and (min-width: 769px) {
    .footer {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding-top: 24px;
      margin-top: 24px;
      font-size: 14px; } }

@media screen and (max-width: 768px) {
  .footer-column + .footer-column {
    padding-top: 12px; } }
