/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
	color:$color_theme_01-02;

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}
	}

	&.half-spacing {

		h1, h2, h3, h4, h5, h6, p, ul, ol {
			padding-top:.75em;

			@media screen and (max-width:768px)
			{
				padding-top:.375em;
			}

			&:first-child {
				padding-top:0;
			}
		}
	}

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_01-01;
	}

	ul, ol {
		padding-left:18px;

		@media screen and (min-width:769px)
		{
			margin-top:-.5em;
		}
	}

	a:not(.btn):not(.link) {
		@include animate;
		@include relative;
		text-decoration:none;
		word-break:break-word;
		border-bottom:1px solid $color_theme_01-01;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_02-01;
				border-bottom:1px solid transparent;
			}
		}
	}

	blockquote {
		@include relative;
		padding:48px 0;
		text-align:center;

		@media screen and (max-width:768px)
		{
			padding:18px 0;
		}

		&:before,
		&:after {
			@include absolute;
			content:"";
			left:96px; right:96px;
			height:1px;
			background:$color_theme_01-01;

			@media screen and (max-width:768px)
			{
				left:24px; right:24px;
			}
		}
		&:before {
			top:0;
		}
		&:after {
			bottom:0;
		}
	}

	hr {
		margin:1.5em 0 0; padding:0;
		width:100%; height:1px;
		background:#E5E5E5;
		border:none;

		@media screen and (max-width:768px)
		{
			margin-top:1em;
		}

		&:first-child {
			margin-top:0;
		}
	}

	table {
		margin-top:1.5em;
		width:100%;
		border-collapse:collapse;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}

		th, td {
			padding:9px 18px;
			border:1px solid #E5E5E5;
		}
	}
}
