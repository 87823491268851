/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- PRIMARY --- */
$color_theme_01-01 : #242430; // Black(ish)
$color_theme_01-02 : #666;    // Gray

$color_theme_02-01 : #C43B2B; // Red
$color_theme_02-02 : #7CC9BF; // Cyan
$color_theme_02-03 : #FFBA52; // Yellow
$color_theme_02-04 : #454658; // Dark Purple

/* --- SYSTEM COLORS --- */
$color_error  : #D91E18;
$color_alert  : #F89406;
$color_valid  : #00B16A;
$color_update : #1E8BC3;

/* --- SOCIAL COLORS --- */
$color_facebook  : #3B5998;
$color_twitter   : #4099FF;
$color_linkedin  : #0077B5;
$color_instagram : #125688;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_family_01 : 'Raleway', sans-serif;
$font_family_02 : 'Gelasio', serif;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_size_base  : 18px;
$font_size_small : 16px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$line_height_base  : 1.6;
$line_height_small : 1.4;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$site_width_large   : 1440px;
$site_width_base    : 1220px;
$site_width_medium  : 980px;
$site_width_small   : 860px;
$site_width_xsmall  : 640px;
$site_width_xxsmall : 420px;

$header_height_01-01 : 96px;
$header_height_01-02 : 56px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FADE IN ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@keyframes fadeIn {
  from { opacity: 0; }
}
