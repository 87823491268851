/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (CASE STUDIES) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.case-studies_item {
    padding-top:1.5em;

    @media screen and (max-width:768px)
    {
        padding-top:2em;
    }
}
    .case-studies_item-thumb {

        @media screen and (max-width:768px)
        {
            padding-bottom:1em;
        }
    }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (APPLICANTS) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.table-applicants_wrapper {
    width:100%;
    overflow-x:auto;
}
.table-applicants {
    min-width:600px;

    th {
        color:$color_theme_01-01;
        font-size:$font_size_small;
        text-align:left;
        background:#F5F5F5;
    }
    td {
        font-size:14px;
    }
}
    .table-applicants_action {
        @include flex-row;

        a {
            flex-shrink:0;
            margin-right:3px;
        }
    }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (PAGINATION) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination {
    @include flex-row;
    justify-content:center;
    margin:0; padding:48px 0 0;
    list-style:none;
}
    .page-item {
        margin:0 3px;

        &.disabled .page-link {
            color:#999;
        }
        &.active .page-link {
            color:#FFF;
            background:$color_theme_02-01;
        }

        @media screen and (min-width:769px)
        {
            &:not(.active):not(.disabled) .page-link:hover,
            &:not(.active):not(.disabled) .page-link:focus {
                background:#E5E5E5;
            }
        }
    }
        .page-link {
            @include animate;
            @include flex-column;
            justify-content:center;
            align-items:center;
            width:48px; height:48px;
            color:$color_theme_01-01;
            background:#F5F5F5;
            border-radius:3px;
        }
