/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
	display:inline-block;
	font-weight:700;
	box-sizing:border-box;

	&.btn_has-icon {
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		align-items:center;

		span {
			padding-left:12px;

			@media screen and (max-width:768px)
			{
				padding-left:9px;
			}

			&:first-child {
				padding-left:0; padding-right:12px;

				@media screen and (max-width:768px)
				{
					padding-right:9px;
				}
			}
		}
		svg {
			display:block;
			flex-shrink:0;
			width:18px; height:18px;
		}

		.btn-icon {
			flex-shrink:0;
		}
	}

	&.icon-minus-90-deg svg {
		transform:rotate(-90deg);
	}

	&.btn-ghost {
		opacity:.24;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				opacity:1;
			}
		}
	}

	&.btn-round {
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		justify-content:center;
		align-items:center;
		width:48px; height:48px;
		border-radius:100%;
		overflow:hidden;

		svg {
			display:block;
			flex-shrink:0;
			width:18px; height:18px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-01 {
	padding:16px 28px;
	border-radius:3px;
}
