/* ------------------------------------------------------------------------------------------------------------------------ */
/* HTML ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
html {
	min-height:100vh;
	background:url('../images/layout/body-bg.jpg') top left no-repeat #F5F5F5;
	background-attachment:fixed;
	background-size:cover;
	object-fit:cover; font-family:'object-fit:cover;';
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
	@include flex-column;
	flex-shrink:0;

	& > .box-01 {
		margin:48px;

		@media screen and (max-width:768px)
		{
			margin:6px;
		}

		& > .box-01_content {
			width:100%;
			box-sizing:border-box;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-container {
	@include flex-column;
	flex:1 1 auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
	text-align:center;

	@media screen and (max-width:768px)
    {
		line-height:1.2;

		strong {
			font-size:18px;
		}
		span {
			font-size:14px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
	padding-top:48px; margin-top:48px;
	border-top:1px solid #E5E5E5;

	@media screen and (min-width:769px)
	{
		@include flex-row;
		justify-content:space-between;
	}
	@media screen and (max-width:768px)
	{
		padding-top:24px; margin-top:24px;
		font-size:14px;
	}
}
	.footer-column {

		@media screen and (max-width:768px)
		{
			& + .footer-column {
				padding-top:12px;
			}
		}
	}
